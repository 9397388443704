#about {
	display: flex;
	width: 90%;
	align-items: center;
	justify-content: center;
	padding: 100px 5%;
	height: 100%;
	min-height: 100%;
	.info-container {
		display: flex;
		flex-direction: column;
		width: 30%;
		max-width: 30%;
		height: 100%;
		justify-content: space-between;
		align-items: center;
		justify-content: center;
		margin-right: 50px;
		h1 {
			font-family: "Be Vietnam Pro";
			font-style: normal;
			font-weight: 800;
			font-size: 45px;
			text-transform: uppercase;
			color: #ff7324;
			margin-bottom: 20px;
			&:after {
				display: flex;
				position: relative;
				content: "";
				padding-bottom: 5px;
				border-bottom: solid 3px #000000;
				width: 40%;
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
		span {
			font-family: "Be Vietnam Pro";
			font-style: normal;
			font-weight: 300;
			font-size: 15px;
			text-align: center;
			color: #000000;
			margin-bottom: 20px;
		}
		button {
			padding: 10px 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			text-align: center;

			color: #000000;
			background-color: white;

			border: 2px solid #ff7324;
			border-radius: 20px;
			cursor: pointer;
			div {
				display: flex;
				font-family: "Be Vietnam Pro";
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				margin-right: 8px;
			}
		}
	}
	.images-container {
		display: flex;
		flex-wrap: wrap;
		width: 40%;
		max-width: 40%;
		gap: 5px;
		height: 100%;
		div {
			display: flex;
			width: calc((100% - 5px) / 2);
			img {
				width: 100%;
			}
		}
	}
}

@media screen and (min-width: 880px) and (max-width: 1180px) {
	#about {
		.info-container {
			width: 40%;
			h1 {
				font-size: 36px;
			}
			span {
				font-size: 12px;
			}
		}
		.images-container {
			width: 50%;
			max-width: 50%;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 880px) {
	#about {
		.info-container {
			width: 40%;
			h1 {
				font-size: 36px;
			}
			span {
				font-size: 12px;
			}
		}
		.images-container {
			width: 50%;
			max-width: 50%;
		}
	}
}

@media screen and (max-width: 768px) {
	#about {
		display: flex;
		flex-direction: column;
		padding: 50px 5%;
		height: auto;
		.info-container {
			width: 90%;
			max-width: 100%;
			margin-right: 0;
			margin-bottom: 50px;
			h1 {
				font-size: 20px;
			}
			span {
				font-size: 12px;
			}
		}
		.images-container {
			width: 90%;
			max-width: 90%;
		}
	}
}
