@import url("https://unpkg.com/flickity@2.3.0/dist/flickity.css");

#menu {
	display: flex;
	width: 90%;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 100px 5%;
	height: 100%;
	min-height: 100%;

	.info-container {
		align-self: center;
		h1 {
			font-family: "Be Vietnam Pro";
			font-style: normal;
			font-weight: 800;
			font-size: 45px;
			text-transform: uppercase;
			color: #ff7324;
			margin-bottom: 20px;
			&:after {
				display: flex;
				position: relative;
				content: "";
				padding-bottom: 5px;
				border-bottom: solid 3px #000000;
				width: 80%;
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
	}
	.menu-category-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 60%;
		overflow: auto;
		white-space: nowrap;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
		ol {
			list-style-type: none;
			padding-left: 0;
			overflow: auto;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			&::-webkit-scrollbar {
				display: none;
			}
			li {
				display: inline-block;
				margin-right: 30px;
				font-family: "Be Vietnam Pro";
				font-style: normal;
				font-weight: 500;
				font-size: 15px;
				cursor: pointer;
				color: #888585;
				&:last-child {
					margin-right: 0px;
				}
				&:after {
					display: block;
					content: "";
					padding-bottom: 5px;
					border-bottom: solid 3px #019fb6;
					transform: scaleX(0);
					transition: transform 1s ease-in-out;
					transform-origin: 0% 50%;
				}
				&:hover:after {
					transform: scaleX(1);
				}
			}
			.selected-category {
				color: #ff7324;
				&:after {
					display: flex;
					position: relative;
					content: "";
					padding-bottom: 5px;
					border-bottom: solid 3px #000000;
					width: 100%;
					transform: scaleX(1);
					transition: none;
				}
			}
		}
	}
	.carousel {
		width: 60%;
		max-width: 60%;
		gap: 10px;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		z-index: 0;
		.prev-button {
			display: flex;
			position: absolute;
			right: calc(100% + 10px);
			top: 100px;
			align-self: center;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			z-index: 2;
		}
		.next-button {
			display: flex;
			position: absolute;
			left: calc(100% + 10px);
			top: 100px;
			align-self: center;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			z-index: 2;
		}

		.carousel-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			width: calc((100% - 30px) / 4);
			z-index: 1;

			img {
				width: 100%;
				aspect-ratio: 1 / 1;
			}
			.name-item {
				font-family: "Be Vietnam Pro";
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				text-align: center;
				max-width: 100%;
				color: #000000;
				word-break: break-word;
				margin-top: 3%;
				margin-bottom: 3%;
			}
			.price-item {
				color: #ff7324;
				font-family: "Be Vietnam Pro";
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: capitalize;
			}
		}
	}
}

@media screen and (min-width: 880px) and (max-width: 1180px) {
	#menu {
		.info-container {
			h1 {
				font-size: 36px;
			}
		}

		.menu-category-content {
			width: 80%;
			max-width: 80%;
			ol {
				li {
					font-size: 12px;
				}
			}
		}
		.carousel {
			width: 80%;
			max-width: 80%;
			.prev-button {
				transform: scale(0.6);
				top: 80px;
			}
			.next-button {
				transform: scale(0.6);
				top: 80px;
			}
			.carousel-item {
				.name-item {
					font-size: 12px;
				}
				.price-item {
					font-size: 13px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 880px) {
	#menu {
		.info-container {
			h1 {
				font-size: 36px;
			}
		}

		.menu-category-content {
			width: 80%;
			max-width: 80%;
			ol {
				li {
					font-size: 12px;
				}
			}
		}
		.carousel {
			width: 80%;
			max-width: 80%;
			.prev-button {
				transform: scale(0.6);
				top: 80px;
			}
			.next-button {
				transform: scale(0.6);
				top: 80px;
			}
			.carousel-item {
				.name-item {
					font-size: 12px;
				}
				.price-item {
					font-size: 13px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#menu {
		padding: 20px 5%;
		.info-container {
			h1 {
				font-size: 20px;
			}
		}

		.menu-category-content {
			width: 90%;
			max-width: 90%;
			ol {
				li {
					font-size: 9px;
				}
			}
		}
		.carousel {
			width: 90%;
			max-width: 90%;
			.prev-button {
				transform: scale(0.6);
				top: 50px;
			}
			.next-button {
				transform: scale(0.6);
				top: 50px;
			}
			.carousel-item {
				.name-item {
					font-size: 9px;
				}
				.price-item {
					font-size: 10px;
				}
			}
		}
	}
}
