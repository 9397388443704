@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Alumni+Sans+Collegiate+One&family=Be+Vietnam+Pro:wght@500&family=Work+Sans:ital,wght@0,100;0,600;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Alumni+Sans+Collegiate+One&family=Be+Vietnam+Pro:wght@500;600&family=Work+Sans:ital,wght@0,100;0,600;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Alumni+Sans+Collegiate+One&family=Be+Vietnam+Pro:wght@500;600&family=Playfair+Display:wght@500&family=Work+Sans:ital,wght@0,100;0,600;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Alumni+Sans+Collegiate+One&family=Baskervville&family=Be+Vietnam+Pro:wght@100;300;500;600&family=Libre+Baskerville&family=Playfair+Display:wght@500&family=Work+Sans:ital,wght@0,100;0,600;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Alumni+Sans+Collegiate+One&family=Baskervville&family=Be+Vietnam+Pro:wght@500;600&family=Libre+Baskerville&family=Playfair+Display:wght@500&family=Work+Sans:ital,wght@0,100;0,600;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&family=Roboto:wght@500&display=swap");
html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
