.sidebar-container {
	display: none;
}

.sidebar-not-display {
	display: flex;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 0;
	padding: 0;
	.button-back-container {
		display: none;
	}
	.sidebar-content {
		display: none;
	}
	.sidebar-button-container {
		display: none;
	}
	transition: 0.5s ease-in;
}

@media screen and (max-width: 1024px) {
	.sidebar-container {
		display: flex;
		position: fixed;
		top: 0;
		height: 100vh;
		flex-direction: column;
		width: 260px;
		padding: 50px;
		align-items: flex-start;
		justify-content: flex-start;
		background-color: white;
		transition: 0.5s ease-in;

		.button-back-container {
			display: flex;
			margin-bottom: 50px;
			cursor: pointer;
		}

		.sidebar-content {
			width: 100%;
			margin-bottom: 50px;
			display: flex;
			nav {
				ol {
					display: flex;
					list-style-type: none;
					padding-left: 0;
					flex-direction: column;
					.li-not-active {
						display: inline-block;
						font-family: "Be Vietnam Pro";
						margin-bottom: 10px;
						font-style: normal;
						width: fit-content;
						font-weight: 500;
						font-size: 15px;
						cursor: pointer;
						color: black;
						span {
							&:after {
								display: flex;
								content: "";
								padding-bottom: 5px;
								border-bottom: solid 3px #019fb6;
								transform: scaleX(0);
								transition: transform 1s ease-in-out;
								transform-origin: 0% 50%;
							}
							&:hover:after {
								transform: scaleX(1);
							}
						}
					}
					.li-active {
						display: inline-block;
						margin-right: 30px;
						font-family: "Be Vietnam Pro";
						font-style: normal;
						font-weight: 500;
						margin-bottom: 10px;
						font-size: 15px;
						width: fit-content;
						cursor: pointer;
						color: black;
						span {
							&:after {
								display: block;
								content: "";
								padding-bottom: 5px;
								border-bottom: solid 3px #019fb6;
								transform: scaleX(1);
								transition: transform 1s ease-in-out;
								transform-origin: 0% 50%;
							}
						}
					}
				}
			}
		}
		.sidebar-button-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			align-self: flex-start;
			color: white;
		}
	}
}
