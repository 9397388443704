.offer-container {
	display: flex;
	flex: 1;
	width: 100%;
	display: flex;
	background-image: url("../../../assets/images/offer/backgroud.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	aspect-ratio: 1440 / 780;
	padding-top: 20px;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	h1 {
		font-family: "Be Vietnam Pro";
		font-style: normal;
		font-weight: 800;
		font-size: 45px;
		text-transform: uppercase;
		color: #ff7324;
		margin-bottom: 50px;
		&::after {
			display: flex;
			position: relative;
			content: "";
			padding-bottom: 10px;
			border-bottom: solid 3px #000000;
			width: 65%;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
	.offer-flatlist {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
		.offer-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-right: 20px;
			&:last-child {
				margin-right: 0px;
			}
			.offer-image {
				border-radius: 10px;
				margin-bottom: 10px;
			}
			.offer-description {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				height: 50px;
				.offer-icon {
					margin-right: 10px;
				}
				.offer-label {
					font-family: "Be Vietnam Pro";
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					text-align: center;

					color: #1e1e1e;
				}
			}
			button {
				padding: 10px 20px;
				display: flex;
				flex-direction: row;
				align-items: center;
				text-align: center;

				color: #000000;

				border: 2px solid #ff7324;
				background-color: transparent;

				border-radius: 20px;
				cursor: pointer;
				div {
					display: flex;
					font-family: "Be Vietnam Pro";
					font-style: normal;
					font-weight: 700;
					font-size: 12px;
					margin-right: 8px;
				}
			}
		}
	}
}

@media screen and (min-width: 880px) and (max-width: 1180px) {
	.offer-container {
		h1 {
			font-size: 36px;
		}
		.offer-flatlist {
			width: 80%;
			max-width: 80%;
			margin-bottom: 50px;
			.offer-item {
				margin-right: 10px;
				width: calc((100% - 20px) / 3);
				.offer-image {
					width: 100%;
					aspect-ratio: 297 / 301;
					border-radius: 10px;
					margin-bottom: 5px;
				}
				.offer-description {
					.offer-label {
						font-size: 15px;
					}
				}
				button {
					div {
						font-size: 10px;
						margin-right: 8px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 880px) {
	.offer-container {
		h1 {
			font-size: 36px;
		}
		.offer-flatlist {
			width: 80%;
			max-width: 80%;
			margin-bottom: 50px;
			.offer-item {
				margin-right: 10px;
				width: calc((100% - 20px) / 3);
				.offer-image {
					width: 100%;
					aspect-ratio: 297 / 301;
					border-radius: 10px;
					margin-bottom: 5px;
				}
				.offer-description {
					.offer-label {
						font-size: 15px;
					}
				}
				button {
					div {
						font-size: 10px;
						margin-right: 8px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.offer-container {
		h1 {
			font-size: 20px;
		}
		.offer-flatlist {
			width: 80%;
			max-width: 80%;
			margin-bottom: 50px;
			.offer-item {
				margin-right: 5px;
				width: calc((100% - 20px) / 3);
				.offer-image {
					width: 100%;
					aspect-ratio: 297 / 301;
					border-radius: 10px;
					margin-bottom: 5px;
				}
				.offer-description {
					.offer-icon {
						margin-right: 5px;
						transform: scale(0.6);
					}
					.offer-label {
						font-size: 9px;
					}
				}
				button {
					padding: 5px 10px;
					div {
						font-size: 8px;
						margin-right: 8px;
					}
				}
			}
		}
	}
}
