.banner-container {
	background-image: url("../../../assets/images/banner2.png");
	aspect-ratio: 1437/498;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	z-index: 0;
	.banner-overlay1 {
		background: linear-gradient(
				107deg,
				#fff -35.37%,
				rgba(255, 255, 255, 0) 18.53%
			),
			linear-gradient(109deg, #fff -2.03%, rgba(255, 255, 255, 0) 45.33%);
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.banner-overlay2 {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: linear-gradient(
				106.84deg,
				#ffffff -35.37%,
				rgba(255, 255, 255, 0) 18.53%
			),
			linear-gradient(
				109.23deg,
				#ffffff -2.03%,
				rgba(255, 255, 255, 0) 45.33%
			);
		transform: matrix(-1, 0, 0, 1, 0, 0);
	}
	.banner-label {
		color: #000;
		font-family: "Be Vietnam Pro";
		font-size: 40px;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
		text-transform: uppercase;
		position: absolute;
		top: 12.5%;
		left: 17.2%;
		max-width: 25.7%;
		width: fit-content;
		flex-wrap: wrap;
		z-index: 2;
	}
}

@media screen and (min-width: 880px) and (max-width: 1180px) {
	.banner-container {
		.banner-label {
			font-size: 32px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 880px) {
	.banner-container {
		.banner-label {
			top: 10%;
			left: 5%;
			max-width: 25%;
			font-size: 32px;
		}
	}
}

@media screen and (max-width: 768px) {
	.banner-container {
		.banner-label {
			top: 10%;
			left: 5%;
			max-width: 30%;
			font-size: 12px;
		}
	}
}
