.intro-container {
	display: flex;
	background-image: url("../../../assets/images/home-about.png");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	aspect-ratio: 1440 / 664;
	min-height: 300px;
	justify-content: center;
	align-items: center;
	position: relative;
	.intro-background {
		display: flex;
		flex: 1;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(36, 19, 3, 0.5);
		justify-content: center;
		align-items: center;
		.intro-content {
			display: flex;
			width: 60%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h1 {
				font-family: "Playfair Display";
				font-style: normal;
				font-weight: 600;
				font-size: 85px;
				text-align: center;
				text-transform: uppercase;
				color: #ffbd3d;
			}
			.intro-content-text {
				font-family: "Be Vietnam Pro";
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				width: 60%;
				color: #ffffff;
				text-align: center;
				margin-bottom: 50px;
			}
			button {
				padding: 10px 20px;
				filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.25));
				background: #ff7324;
				border-radius: 20px;
				font-family: "Be Vietnam Pro";
				font-style: normal;
				font-weight: 700;
				font-size: 15px;
				display: flex;
				align-items: center;
				text-align: center;
				text-transform: uppercase;
				outline: none;
				color: #ffffff;
				border: none;
				cursor: pointer;
				margin-bottom: 50px;
				a {
					text-decoration: none;
					color: #ffffff;
				}
			}
		}
	}
}

@media screen and (min-width: 880px) and (max-width: 1180px) {
	.intro-container {
		.intro-background {
			width: 80%;
			.intro-content {
				h1 {
					font-size: 62px;
				}
				.intro-content-text {
					width: 60%;
					font-size: 14px;
				}
				button {
					font-size: 12px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 880px) {
	.intro-container {
		.intro-background {
			.intro-content {
				h1 {
					font-size: 48px;
				}
				.intro-content-text {
					width: 80%;
					font-size: 14px;
				}
				button {
					font-size: 12px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.intro-container {
		.intro-background {
			.intro-content {
				width: 80%;
				margin-top: 50px;
				h1 {
					font-size: 30px;
				}
				.intro-content-text {
					width: 80%;
					font-size: 10px;
				}
				button {
					font-size: 8px;
				}
			}
		}
	}
}
