.feedback-container {
	display: flex;
	background-image: url("../../../assets/images/feedback/feedback-background.png");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	aspect-ratio: 1257 / 479;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1;
	flex-direction: column;
	padding-top: 100px;
	.feedback-overlay {
		display: flex;
		background-color: rgba(240, 239, 239, 0.3);
		flex: 1;
		width: 100%;
		height: 75%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
	h1 {
		font-family: "Be Vietnam Pro";
		font-style: normal;
		font-weight: 800;
		font-size: 45px;
		text-transform: uppercase;
		color: #ff7324;
		margin-bottom: 20px;
		&:after {
			display: flex;
			position: relative;
			content: "";
			padding-bottom: 5px;
			border-bottom: solid 3px #000000;
			width: 20%;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
	.carousel {
		width: 80%;
		max-width: 80%;
		gap: 30px;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		z-index: 0;
		margin-bottom: 50px;
		.prev-button {
			display: flex;
			position: absolute;
			right: calc(100% + 10px);
			bottom: 60px;
			align-self: center;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			z-index: 2;
		}
		.next-button {
			display: flex;
			position: absolute;
			left: calc(100% + 10px);
			bottom: 60px;
			align-self: center;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			z-index: 2;
		}

		.carousel-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: calc((100% - 60px) / 3 - 40px);
			z-index: 1;
			aspect-ratio: 308 / 262;

			background: #ffffff;
			box-shadow: 7px 6px 21px rgba(0, 0, 0, 0.1);
			border-radius: 15px;
			padding: 20px;
			.carousel-item-text-container {
				display: flex;
				flex: 1;
				height: 60%;
				overflow: hidden;
				margin-bottom: 20px;
				.carousel-item-text {
					text-align: justify;
					overflow-y: scroll;
					text-overflow: ellipsis;

					font-family: "Be Vietnam Pro";
					font-style: italic;
					font-weight: 300;
					font-size: 12px;
					line-height: 15px;

					color: #000000;
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* Firefox */
					&::-webkit-scrollbar {
						display: none;
					}
				}
			}
			.carousel-item-info {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				.carousel-item-avatar {
					width: 60px;
					aspect-ratio: 1 / 1;
					border-radius: 50%;
					object-fit: cover;
					margin-right: 10px;
				}
				.carousel-item-name {
					font-family: "Be Vietnam Pro";
					font-style: normal;
					font-weight: 600;
					font-size: 15px;
					line-height: 19px;
					color: #ff7324;
				}
			}
		}
	}
}

@media screen and (min-width: 880px) and (max-width: 1180px) {
	.feedback-container {
		h1 {
			font-size: 36px;
		}
		.carousel {
			gap: 20px;
			.carousel-item {
				width: calc((100% - 40px) / 3 - 40px);
				aspect-ratio: 350 / 400;
				.carousel-item-text-container {
					.carousel-item-text {
						font-size: 10px;
						line-height: 12px;
					}
				}
				.carousel-item-info {
					.carousel-item-avatar {
						width: 50px;
					}
					.carousel-item-name {
						font-size: 12px;
						line-height: 15px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 880px) {
	.feedback-container {
		h1 {
			font-size: 36px;
		}
		.carousel {
			gap: 20px;
			.carousel-item {
				width: calc((100% - 40px) / 3 - 40px);
				aspect-ratio: 350 / 400;
				.carousel-item-text-container {
					.carousel-item-text {
						font-size: 10px;
						line-height: 12px;
					}
				}
				.carousel-item-info {
					.carousel-item-avatar {
						width: 50px;
					}
					.carousel-item-name {
						font-size: 12px;
						line-height: 15px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 480px) and (max-width: 768px) {
	.feedback-container {
		h1 {
			font-size: 20px;
		}
		.carousel {
			gap: 20px;
			.prev-button {
				right: calc(100% + 5px);
				transform: scale(0.6);
			}
			.next-button {
				left: calc(100% + 5px);
				transform: scale(0.6);
			}
			.carousel-item {
				width: calc((100% - 20px) / 2 - 40px);
				padding: 20px;
				aspect-ratio: 122 / 134;
				.carousel-item-text-container {
					.carousel-item-text {
						font-size: 12px;
						line-height: 15px;
					}
				}
				.carousel-item-info {
					.carousel-item-avatar {
						width: 40px;
					}
					.carousel-item-name {
						font-size: 12px;
						line-height: 15px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.feedback-container {
		h1 {
			font-size: 20px;
		}
		.carousel {
			gap: 20px;
			.prev-button {
				right: calc(100% + 5px);
				transform: scale(0.6) translate(50%, 0);
			}
			.next-button {
				left: calc(100% + 5px);
				transform: scale(0.6) translate(-50%, 0);
			}
			.carousel-item {
				width: calc((100% - 20px) / 2 - 40px);
				padding: 20px;
				aspect-ratio: 250 / 350;
				.carousel-item-text-container {
					.carousel-item-text {
						font-size: 8px;
						line-height: 10px;
					}
				}
				.carousel-item-info {
					.carousel-item-avatar {
						width: 30px;
					}
					.carousel-item-name {
						font-size: 8px;
						line-height: 12px;
					}
				}
			}
		}
	}
}
